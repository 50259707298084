import React, { useState } from 'react'
import { isWithinInterval, isAfter, isBefore, formatISO } from './lib/date'
import {
  Contract,
  CountryID,
  HourCodePolicy,
  HoursContractSeason,
  InvoiceTaskInfo,
  PolicyGroupType,
  TravelPolicy22Metadata,
  TravelPolicyOldMetadata,
  WorkEntry,
  WorkEntryPolicyMetadata,
} from './lib/domain'
import { SettingsState } from './lib/reducers/settings'
import classnames from 'classnames'
import styles from './css/PolicyGroup.module.css'

import { useAppSelector } from './lib/hooks'
import _ from 'lodash'

export const resolvePolicyGroup = (
  workEntry: WorkEntry,
  day: string,
  hourCodes: InvoiceTaskInfo[],
  hourCodePolicies: { [policyGroup: string]: HourCodePolicy[] }
): HourCodePolicy | undefined => {
  const { hourCode } = workEntry
  if ('' === hourCode || !hourCode || !hourCodes) return undefined
  const invoiceTaskInfo = hourCodes.find((taskInfo) => taskInfo.name === hourCode)
  const groupsForTaskInfo = invoiceTaskInfo?.policyGroup ? hourCodePolicies[invoiceTaskInfo?.policyGroup] : undefined

  return groupsForTaskInfo?.find((group) => {
    const { validityStart, validityEnd } = group
    if (validityStart && validityEnd) return isWithinInterval({ start: validityStart, end: validityEnd }, day)
    else if (validityStart) return isAfter(day, validityStart) || validityStart === day
    else if (validityEnd) return isBefore(day, validityEnd) || validityEnd === day
    else return false // Assume policy is disabled
  })
}

type SupportedCountries = Extract<CountryID, 'PT'> | 'default'

type NameComponent = {
  name: string
  component?: React.FunctionComponent<PolicyGroupProps>
  initialMetadata: { [key in SupportedCountries]?: WorkEntryPolicyMetadata }
}

type SupportedPolicyType = {
  [policyGroup: PolicyGroupType]: NameComponent[]
}

const defaultTravel22PolicyMetadata = new TravelPolicy22Metadata(false, 'pay50')
const defaultTravel22PolicyMetadataPT = new TravelPolicy22Metadata(false, 'flex50')

const knownPolicyNames: SupportedPolicyType = {
  travel: [
    {
      name: 'travel22',
      component: Travel22PolicyComponent,
      initialMetadata: { PT: defaultTravel22PolicyMetadataPT, default: defaultTravel22PolicyMetadata },
    },
    {
      name: 'travel-old',
      initialMetadata: { default: new TravelPolicyOldMetadata() },
    },
  ],
}

export const componentForPolicy = ({ policyGroup, name }: HourCodePolicy) =>
  knownPolicyNames[policyGroup]?.find((nameComponent) => nameComponent.name === name)?.component

export const requestInitialMetadata = ({ policyGroup, name }: HourCodePolicy, contract: HoursContractSeason) => {
  const initialMetadata = knownPolicyNames[policyGroup]?.find(
    (nameComponent) => nameComponent.name === name
  )?.initialMetadata
  return (
    (initialMetadata && contract.company.country && _.get(initialMetadata, contract.company.country)) ||
    initialMetadata?.default
  )
}

const isNLAfterJuly2022 = (country: CountryID, day: string) => country === 'NL' && isAfter(day, formatISO('2022-07-31'))

export const isSupportedPolicy = (contract: Contract, policy: HourCodePolicy, day: string) =>
  policy &&
  contract &&
  !contract.company.subContractor &&
  (contract.company.country === 'FI' ||
    isNLAfterJuly2022(contract.company.country, day) ||
    contract.company.country === 'PT') &&
  (contract.company.name === 'Reaktor Group' ||
    contract.company.name === 'Reaktor BV' ||
    contract.company.name === 'Reaktor Innovations' ||
    contract.company.name === 'Reaktor Portugal') &&
  componentForPolicy(policy) !== undefined

export type PolicyGroupProps = {
  entry: WorkEntry
  modifyEntry: (entry: Partial<WorkEntry>) => void
  locale: SettingsState['locale']
  policyGroup: HourCodePolicy
  contract: HoursContractSeason
  startEndShown: boolean
}

const isTravelPolicy22Metadata = (
  policyMetadata: WorkEntryPolicyMetadata
): policyMetadata is TravelPolicy22Metadata => {
  return policyMetadata.policy === 'travel22'
}

// workEntry.hourCode coming from Hours API does not contain suffixes such as '-ic', '-flex50', '-pay50'.
// it may contain policyMetadata which contains the Metadata used to build the UI state.
// here it will always contain supported policy metadata as it has been checked by supportedPolicy earlier.
const readWorkEntryPolicyMetaData = (
  policyMetadata: WorkEntryPolicyMetadata | undefined,
  contract: HoursContractSeason
): TravelPolicy22Metadata => {
  if (policyMetadata && isTravelPolicy22Metadata(policyMetadata)) {
    return new TravelPolicy22Metadata(policyMetadata.isIntercontinental, policyMetadata.compensation)
  } else if (contract.company.country === 'PT') return defaultTravel22PolicyMetadataPT
  else return defaultTravel22PolicyMetadata
}

function Travel22PolicyComponent(props: PolicyGroupProps) {
  const { locale, entry, modifyEntry, contract } = props
  const day = useAppSelector((state) => state.data.personalHoursClient.currentlyEditingDay)
  const isHourly = contract.type === 'hourly'
  const isPT = contract.company?.country === 'PT'
  const isNL = contract.company?.country === 'NL'
  const expectedHours = contract.expectedWorkingHoursPerDay(day)
  const [metadata, _setMetadata] = useState(readWorkEntryPolicyMetaData(entry.policyMetadata, contract))
  const setMetadata = (policyMetadata: TravelPolicy22Metadata) => {
    _setMetadata(policyMetadata)
    modifyEntry({ policyMetadata })
  }
  const ic = metadata.isIntercontinental
  // preserves the state when randomly clicking the intercontinental / one continent radio buttons
  const [compensationUndo, setCompensationUndo] = useState(ic ? defaultTravel22PolicyMetadata : metadata)
  const flex = metadata.compensation === 'flex50'
  const pay50 = metadata.compensation === 'pay50'

  return (
    <div data-test='travel-policy-travel22' className={styles.travel22}>
      <div className={styles.radioButtonGroup}>
        <p>
          <span>{locale.texts.hourCodePolicy.travel22.area}</span>
        </p>
        <div
          data-test='travel-policy-button-local'
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          className={classnames(styles.selectableRadio, { [styles.selected!]: !ic })}
          onClick={() => {
            setMetadata(new TravelPolicy22Metadata(false, compensationUndo.compensation))
          }}
        >
          <div className={styles.radioButton}>
            <div></div>
          </div>
          <div>
            <p className={styles.buttonTitle}>{locale.texts.hourCodePolicy.travel22.local}</p>
            <p>{locale.texts.hourCodePolicy.travel22.localDescription}</p>
          </div>
        </div>
        <div
          data-test='travel-policy-button-ic'
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          className={classnames(styles.selectableRadio, { [styles.selected!]: ic })}
          onClick={() => {
            if (flex || pay50) setCompensationUndo(metadata)
            setMetadata(new TravelPolicy22Metadata(true, undefined))
          }}
        >
          <div className={styles.radioButton}>
            <div></div>
          </div>
          <div>
            <p className={styles.buttonTitle}>{locale.texts.hourCodePolicy.travel22.intercontinental}</p>
            <p>{locale.texts.hourCodePolicy.travel22.intercontinentalDescription}</p>
          </div>
        </div>
      </div>
      {!(ic || isHourly || isPT) && (
        <div className={styles.radioButtonGroup}>
          <p>
            <span>{locale.texts.hourCodePolicy.travel22.compensation}</span>
          </p>
          <div
            data-test='travel-policy-button-pay50'
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            className={classnames(styles.selectableRadio, { [styles.selected!]: pay50 })}
            onClick={() => {
              const meta = new TravelPolicy22Metadata(false, 'pay50')
              setMetadata(meta)
              setCompensationUndo(meta)
            }}
          >
            <div className={styles.radioButton}>
              <div></div>
            </div>
            <div>
              <p className={styles.buttonTitle}>{locale.texts.hourCodePolicy.travel22.compensationSalary}</p>
              <p className={styles.hourAmount}>{locale.texts.hourCodePolicy.travel22.compensationSalaryHours}</p>
            </div>
          </div>
          <div
            data-test='travel-policy-button-flex50'
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            className={classnames(styles.selectableRadio, { [styles.selected!]: flex })}
            onClick={() => {
              const meta = new TravelPolicy22Metadata(false, 'flex50')
              setMetadata(meta)
              setCompensationUndo(meta)
            }}
          >
            <div className={styles.radioButton}>
              <div></div>
            </div>
            <div>
              <p className={styles.buttonTitle}>{locale.texts.hourCodePolicy.travel22.compensationFlexitime}</p>
              {!isNaN(parseFloat(entry.hours)) && (
                <p className={styles.hourAmount}>
                  {locale.texts.hourCodePolicy.travel22.compensationFlexitimeHours(parseFloat(entry.hours) / 2)}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {ic && (
        <div data-test='travel-policy-infoblock-ic' className={classnames(styles.infoBlock, styles.radioButtonGroup)}>
          <p>
            <span>{locale.texts.hourCodePolicy.travel22.intercontinentalCompensation}</span>
          </p>
          <p>
            {expectedHours
              ? locale.texts.hourCodePolicy.travel22.intercontinentalCompensationDescription(expectedHours)
              : '?'}
            {isPT && (
              <a
                target='_blank'
                href='https://sites.google.com/reaktor.fi/reaktorlisbon/travel/for-lisbon-locals'
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.intercontinentalCompensationLinkTextPT}
              </a>
            )}
            {isNL && (
              <a
                target='_blank'
                href='https://sites.google.com/reaktor.fi/reaktor-amsterdam/employment-matters/handbook/business-travel'
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.intercontinentalCompensationLinkTextNL}
              </a>
            )}
            {!isPT && !isNL && (
              <a
                target='_blank'
                href={
                  locale.language === 'FI'
                    ? 'https://sites.google.com/reaktor.fi/hali-infot/ty%C3%B6suhdeasiat/matkustaminen'
                    : 'https://sites.google.com/reaktor.fi/hug-info/employment-matters/traveling'
                }
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.intercontinentalCompensationLinkText}
              </a>
            )}
            .
          </p>
        </div>
      )}
      {!ic && (
        <div
          data-test='travel-policy-infoblock-local'
          className={classnames(styles.infoBlock, styles.radioButtonGroup)}
        >
          {isPT && (
            <p>
              {locale.texts.hourCodePolicy.travel22.compensationHelpPT}
              <a
                target='_blank'
                href='https://sites.google.com/reaktor.fi/reaktorlisbon/travel/for-lisbon-locals'
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.compensationLinkTextPT}
              </a>
              .
            </p>
          )}
          {isNL && (
            <p>
              {locale.texts.hourCodePolicy.travel22.compensationHelpNL}
              <a
                target='_blank'
                href='https://sites.google.com/reaktor.fi/reaktor-amsterdam/employment-matters/handbook/business-travel'
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.compensationLinkTextNL}
              </a>
              .
            </p>
          )}
          {!isPT && !isNL && (
            <p>
              {locale.texts.hourCodePolicy.travel22.compensationHelp}
              <a
                target='_blank'
                href={
                  locale.language === 'FI'
                    ? 'https://sites.google.com/reaktor.fi/hali-infot/ty%C3%B6suhdeasiat/matkustaminen'
                    : 'https://sites.google.com/reaktor.fi/hug-info/employment-matters/traveling'
                }
                rel='noopener noreferrer'
              >
                {locale.texts.hourCodePolicy.travel22.compensationLinkText}
              </a>
              .
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export function PolicyGroup(props: PolicyGroupProps) {
  const Component = componentForPolicy(props.policyGroup)
  if (Component) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <div className={classnames(styles.policyGroup, { [styles.startEndShown!]: props.startEndShown })}>
        <Component {...props} />
      </div>
    )
  } else return null
}
